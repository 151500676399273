.access_list {
	background-color: #faf9d4;
}

.file_upload_container {
	border: 1px solid #ccc;
	border-radius: 12px;
}

.ofc_icon {
  width: 35px;
  height: 35px;
}

.form-heading {
	font-size: 24px;
}

.change_pic_container {
	position: relative;
	
}

.upload-icon {
	position: relative;
	bottom: 0;
	right: 0;
	width: 100%;
	display: flex;
	top: -20px;

}


