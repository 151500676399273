.time-picker .rs-picker, .rs-picker-menu{
	z-index: 1300 !important
}

.invalid-timepicker [role='combobox'] {
	border: 1px solid red !important;
	color: red !important;
}

.invalid-timepicker span {
	color: red !important;
}