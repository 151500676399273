@use '../../scss/abstracts/' as *;

.pcc_logo_container {
	position: absolute;
	top: 3%;
	left: 5%;
	width: 200px;
}

.footer-text {
	position: fixed;
	bottom: 3%;
	left: 5%;
	color: #000;
	font-family: "Inter-Regular";
	font-size: 14px;
}

.powered_by_text {
	position: relative;
	top: -12px;
	left: 35px;
}

.wave {
	transform: scale(.6)
}

.scale {
	width: 90px;
}

.otp-details {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.otp-field {
	width: calc(100%/6);
	padding: 7px !important;
	margin-right: 10px !important;
	background: transparent;
	color: $black;
	outline: none;
	border: none;
	text-align: center;
	border-bottom: 2px solid $black;
	font-weight: bold;
}

.otp-container {
	width: 100%;
	overflow: hidden;
}

.login {
	width: 100vw;
	height: 100vh;
	border-radius: 0 0 15%  0;
	background-color: #f5f5f5;
}

.login .logo {
	background-size: contain;
	height: auto !important;
	width: 30%;
	margin: 7% 35%;
}

.login .wave{
	position: absolute;
	left: 10%;
	height: 100%;
	z-index: 1;
	width: 45%;
	background-size: contain;
	display: flex;
	align-items: center
}

.login .login_container{
	position: absolute;
    width: 35vw;
    padding: 0 2rem;
    margin-left: 54vw;
    background-color: #fff;
    height: 70vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
    display: flex;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
}

.login form{
	width: 100%;
}

.login input.phone {
	border: 1px solid #ccc;
	border-radius: 12px;
}

.login .login_btn{
	display: block;
	padding: 1rem;
	outline: none;
	font-size: 1.2rem;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	transition: .5s;
}

.login .btn:hover{
	background-position: right;
}

@media screen and (max-width: 1050px){
	.login .login_container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	.login form{
		width: 290px;
	}

	.login .login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.login .img img{
		width: 400px;
	}
}

@media screen and (max-width: 1080px) {
	.login .wave{
		display: none;
	}

	.login .login_container {
		position: absolute;
	    width: 90vw;
	    height: 50vh;
	    margin: 18vh 5vw;
	    padding: 0 2rem;
	   
	    background-color: #fff;
	    height: 70vh;
	   
	    display: flex;
	    align-items: center;
	    border-radius: 12px;
	    justify-content: center;
	}
}