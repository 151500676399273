@use '../../scss/abstracts/' as *;

.table-container {
  max-height: calc(100vh - 420px) !important;
  overflow: auto;
}

.table-container thead {
  position: sticky;
  top: 0px;
}

.table-container thead th {
  background-color: #8F8080;
  color: $white;
}

thead button div, thead button div span svg {
	/*color: $red !important; Amit - Patch*/ 
	text-transform: uppercase;
}

.tss-tlx3x1-MUIDataTableToolbar-root div svg {
	/*color: $red !important; Amit - Patch*/ 
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
	margin: 0 1.7rem;
	border: 1px solid #D7D7D7;
	border-radius: 8px;
}

tbody tr td:last-child {
	justify-content: flex-end;
}
.employee table thead th, .employee table thead th{
}

.employee tbody tr td:last-child div {
	word-break: break-word;
}

.shift thead th {
	width: 20%;
}

.payroll thead th {
	width: 16.5%;
}

.jobrole thead th, .emprole thead th {
	width: 33%;
}

table th.MuiTableCell-root {
	background-color: #E7EBEE !important;
}