.filter_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
}

.filter_section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


@media screen and (min-width: 768px) and (max-width: 898px) {
  .filter_container {
    flex-direction: column;
    display: block;
  }

  .filter_section {
	display: block;
  }

  .filter_section .date_picker {
  	width: 100%;
  }

  .go-btn {
  	width: 60%;
  	margin: 10px 20%;
  }
}


@media screen and (max-width: 767px) {
  .filter_container {
    flex-direction: column;
    display: block;
  }

  .filter_section {
	display: block;
  }

  .filter_section .date_picker {
  	width: 100%;
  }

  .go-btn {
  	width: 60%;
  	margin: 10px 20%;
  }
}