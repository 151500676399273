.access_list {
	background-color: #faf9d4;
}

.ofc_icon {
  width: 35px;
  height: 35px;
}

.form-heading {
	font-size: 24px;
}

.user-info__img {
	position: relative;
}

.upload-icon {
	position: absolute;
	right: 0px;
	bottom: 0;
	width: 100px;
	height: 100px;
}



