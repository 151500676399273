@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.logs .rs-picker-date-menu {
  left: 75vw !important;
}

.leave .rs-picker-daterange-menu {
  left: 52vw !important;
}

.leave textarea {
  resize: none;
}

.no-access-container {
  min-height: calc(100vh - 235px);
  text-align: center;
  padding: 1rem;
}

.circle_image_container {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 60px;
    height: 60px;
  }
}

.circular-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #EC1D24; /* Adjust the border size and color as needed */
  object-fit: cover; /* Ensures the image covers the container */
}

.small_icon {
  width: auto;
  height: 18px;
}

.amber {
  color: #FF7E00 !important;
}

.circle_logo {
  width: 50px;
}

.page_h1_heading {
  font-family: Inter-Regular;
  font-size: 20px;
  color: #232323;
}

.page_le_text {
  font-family: Inter-Regular;
  font-size: 20px;
  color: $grayText;
}

.dialog_h2_heading {
  font-family: Inter-Medium;
  font-size: 24px;
  color: $dialogHeadingText;

  @include mobile {
    font-size: 12px;
  }
}

.dialogSubText {
  font-family: Inter-Regular;
  font-size: 14px;
  color: $dialogSubText;
}

a {
  color: $txtColor !important;
  text-decoration: none !important;
}

.page_logo {
  width: 105px;
  height: auto;
  padding-right: 1rem;
}

.logo_red {
  background: $red;
  color: #FFF;
  border: 5px;
  padding: 3px 2px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 4px;
  margin-right: 5px;
}

.logo_black {
  color: #1E1E1E;
  font-size: 24px;
  display: flex;
  align-items: center;

  @include mobile {
    font-size: 14px;
  }
}

.grayColor {
  color : $grayText;
}

.font_13 {
  font-size: 13px;
}

.font_16 {
  font-size: 16px;
}

.font_20 {
  font-size: 20px;
}

.inter_regular {
  font-family: Inter-Regular;
}

.inter_medium {
  font-family: Inter-Medium;
}

.inter_light {
  font-family: Inter-Light;
}

.powered_by {
  color: #939393;
  font-size: 16px;
  font-family: "Inter-Regular";
}

.a11y {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}


:root {
  --rs-picker-value: #4E4D4F;
  --rs-listbox-option-hover-text: #4E4D4F;
  --rs-listbox-option-selected-text: #4E4D4F;
  --rs-input-focus-border: #ccc;
  --rs-listbox-option-selected-bg: #F1E1E2;
  --rs-listbox-option-hover-bg : #FAEEEE;
  --rs-calendar-cell-selected-hover-bg: #EC1D24;
  --rs-bg-active: #EC1D24;
  --rs-btn-link-text :  #EC1D24;
  --rs-btn-link-hover-text :  #EC1D24;
  --rs-btn-primary-bg :  #EC1D24;
  --rs-btn-primary-hover-bg :  #EC1D24;
  --rs-btn-link-active-text :  #EC1D24;
  --rs-calendar-date-selected-text: #FAEEEE;
  --rs-calendar-range-bg: #FAEEEE;
  --rs-border-primary: #7a7a7a;
  --bs-btn-color: #00FF00;
  --bs-btn-border-color:  #EC1D24;
}

.btn-outline-danger {
  --bs-btn-color: #EC1D24;
  --bs-btn-border-color:  #EC1D24;
  --bs-btn-hover-color : #fff;
  --bs-btn-hover-bg : #EC1D24;
}

.btn-danger {
  --bs-btn-bg: #EC1D24;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.rounded-btn {
  border: 1px solid $red;
  border-radius: 20px;
  min-width: 130px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 20px;
  color: $red;
}

.MuiDialog-container .MuiPaper-root {
  border-radius: 16px !important;
}

.field {
  color: rgba(0, 0, 0, 0.6);
  font-size: .8rem;
}

th {
  text-align: center !important;
}

html {
  overflow-x: hidden;
}

.ax-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.ax-button:focus {
  outline: none;
}

p {
  margin-bottom: 0 !important;
}

table th div, tbody tr td {
  font-size: .65rem !important;
}

.select .MuiFormControl-root {
  margin : 0 !important;
}

.date_picker {
  padding: 8px 14px;
  border-radius: 5px;
  border: 1px solid var(--rs-border-primary);
  color: var(--rs-text-secondary);
  font-size: 12px;
}

.date_picker:hover {
  border-color: #ccc;
}

.Mui-selected {
  color: $red !important;
}

.container-fluid, .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5) !important;
  padding-left: calc(var(--bs-gutter-x) * .5) !important;
}

[role="tabpanel"] .row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.red {
  color: $red; 
}

.MuiFormControl-root {
  width: 100%;
}

body {
  font-family: $fontFamily;
  color: $txtColor;
  /*font-size: $fontSizeNormal;*/
  line-height: 1.5;
  background-color: $bodyBg;
  /*overflow-x: hidden;*/
}

a {
  text-decoration: none;
  color: unset;
}

img {
  width: 100%;
}

ul,
li {
  list-style-type: none;
}

.mb {
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.title {
  font-size: $fontSizeMedium;
  font-weight: 700;
}

.txt-success {
  color: $green;
}

.txt-danger { 
  color: $red;
}

table thead th div , table thead th div svg {
  color: $red !important;
  font-weight: bold;
}

@media screen and (min-width: 1296px) and (max-width: 1380px) {
  .leave .rs-picker-daterange-menu {
    left: 52vw !important;
  }

  .logs .rs-picker-date-menu {
    left: 75vw !important;
  }
}

@media screen and (min-width: 1081px) and (max-width: 1295px) {
  .leave .rs-picker-daterange-menu {
    left: 45vw !important;
  }

  .logs .rs-picker-date-menu {
    left: 75vw !important;
  }
}

@media screen and (min-width: 961px) and (max-width: 1080px) {
  .leave .rs-picker-daterange-menu {
    left: 50px !important;
  }

  .logs .rs-picker-date-menu {
    left: 75vw !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .leave .rs-picker-daterange-menu {
    left: 10vw !important;
  }

  .logs .rs-picker-date-menu {
    left: 10vw !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
  
  h3 {
    font-size: 1rem !important;
  }

  #dtBasicExample {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .leave .rs-picker-daterange-menu {
    left: 50px !important;
  }

  .logs .rs-picker-date-menu {
    left: 10vw !important;
  }

  h3 {
    font-size: .9rem !important;
  }

  #dtBasicExample {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
    width: 100% !important;
  }

  .table-container {
    max-height: 100px;
    overflow: auto;
  }

  .table-container thead {
    position: sticky;
    top: 0px;
  }
}