@use '../../scss/abstracts/' as *;

.topnav {
    @include flex(center, space-between);
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }
}

.smmc_text {
    color: #010101;
    font-szie: 16px;
}

.sidebar-toggle {
    --size: 40px;
    width: var(--size);
    height: var(--size);
    @include flex(center, center);
    cursor: pointer;
    display: none;

    @include tablet {
        display: flex;
    }
    
    i {
        font-size: 3rem;
        color: $red;
    }
}