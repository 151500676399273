@use '../../scss/abstracts/' as *;

.user-info {
    @include flex(center, flex-start);
    border-radius: $borderRadius;
    height: 50px;
    /*width: max-content;*/

    &__img {
        --size: 70px;
        width: var(--size);
        height: var(--size);
        position: relative;
        left: 7px;
       
        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }
    }

    &__name {
        padding: 0 15px;
        font-size: $fontSizeNormal;
        font-weight: 700;

        @include tablet {
            padding: 0 $mobileSpacing;
        }

        @include mobile {
            font-size: $fontSizeNormal;
            padding: 0 12px;
        }

        &__entity {
            text-wrap: pretty;
            word-wrap: wrap;
        }
    }
}
