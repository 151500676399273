@font-face {
    font-family: "Inter-Regular";
    src: local("./fonts/Inter-Regular.ttf"),
    url("./fonts/Inter-Regular.ttf") format("truetype");
}


@font-face {
    font-family: "Inter-Medium";
    src: local("./fonts/Inter-Medium.ttf"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Light";
    src: local("./fonts/Inter-Light.ttf"),
    url("./fonts/Inter-Light.ttf") format("truetype");
}


