@use '../scss/abstracts/' as *;
@import "rsuite/dist/rsuite.css";

.rs-picker-toggle {
	border-color: #ccc !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, 
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, 
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
    border-color: #ccc !important;
    outline: none !important;
}

.rs-picker-menu, .rs-picker-select-menu {
	z-index: 1300 !important;
}

span .rs-picker-select-menu-item .rs-picker-select-menu-item-active .rs-picker-select-menu-item-focus {
	color: $red important;
}

.rs-picker-select-menu-item:hover {
	color: $red !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
	color: $red !important;
}

.rs-picker-toggle-value {
	color: $red important;
}

.datepicker [role="combobox"] {
	border-radius: 30px;
	background-color: transparent !important;
	padding-top: 12px !important;
	padding-bottom: 12px !important;
	border: 1px solid #C6C9CC;
}

.datepicker .rs-stack-item {
	@include mobile {
		width: 100%;
	}
}

.Mui-focused {
	color: $black !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color:  $white !important;
}

.rs-picker-toggle-caret{
	top: 10px !important;
}