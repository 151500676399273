.card-view {
	background-color: #F5F5F5;
	border: 3px solid #fff;
	border-radius: 4px;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

.time-in {
	color: #5D8C8E;
	font-size: 13px;
	font-weight: bold;
}

.time-out {
	color: #A65A61;
	font-size: 13px;
	font-weight: bold;
}

.duration {
	color: #E1C869;
	font-size: 13px;
	font-weight: bold;
}

.others {
	color: #4E4E4E;
	font-size: 13px;
	font-weight: bold;
}