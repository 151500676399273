$bodyBg: #E7EBEE;
$mainBg: #F9F9FC;
$mainColor: #8624DB;
$txtColor: #4E4D4F;
$purple: #8624DB;
$orange: #FF9066;
$white: #fff;
$black: #000;
$green: #4CAF50;
$red: #EC1D24;
$lightBlack: #333437;
$semiBlack: #1D1E21;
$fontFamily: 'Roboto', sans-serif;
$grayText: #717171;

$dialogHeadingText: #3A3A3A;
$dialogSubText : #797575;

$fontSizeNormal: .8rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 100px; /*384px*/
$sideBarLogoHeight: 150px; /*171px*/
$spacing: 48px;
$mobileSpacing: 24px;
$borderRadius: 30px;

$mobile-width: 600px;
$tablet-width: 1366px;
