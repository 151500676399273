@use '../../scss/abstracts/' as *;

.MuiTabs-scroller {
	[role="tablist"] {
		/*justify-content: center;*/
	}

    @include tablet {
		[role="tablist"] {
			justify-content: flex-start;
		}
    }
}

.MuiTab-root {
	color: $black !important;
	font-family: "Inter-Regular";
	font-size: 16px !important;
}

.MuiTabs-scroller {
  overflow-x: scroll !important;
}

.MuiTabs-scroller::-webkit-scrollbar {
  display: none;
}

 .MuiBox-root {
	padding: 0 !important;
}

[role="tabpanel"] .MuiPaper-root {
	box-shadow : 0
}

.MuiTabs-indicator {
	height: 5px !important;
	border-radius: 5px;
}


