@use '../../scss/abstracts/' as *;

.select .MuiOutlinedInput-root {
	border-radius: 25px;
	padding: 5px 14px !important;
}

.select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, .select .MuiSelect-select {
	padding: 5px 14px !important;
}

.select .MuiFormControl-root  {
	min-width: 160px;
}

.select label {
	font-family: "Inter-Regular";
	color: $black;
	font-size: 16px;
}

.mui-datatables-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .mui-datatables-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
	color: $red;
}